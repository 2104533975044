<template>
  <!-- <div class="navTitle"><img src="../assets/img/arrl.png" alt="">消息</div> -->
  <div class="tabbar">
    <router-link v-if="nav=='home'" class="tab" to="/">
        <div class="tabimg">
            <img style="height: 6.6vw;" src="../assets/img/shouye.png">
        </div>
        <div class="tabtext active">首页</div>
    </router-link>
    <router-link v-else class="tab" to="/">
        <div class="tabimg">
            <img style="height: 6.6vw;" src="../assets/img/shouye1.png">
        </div>
        <div class="tabtext">首页</div>
    </router-link>
    <router-link v-if="nav=='news'" class="tab" to="/news/0">
        <div class="tabimg">
            <img style="height: 6vw;" src="../assets/img/zixun2.png">
        </div>
        <div class="tabtext active">资讯</div>
    </router-link>
    <router-link v-else class="tab" to="/news/0">
        <div class="tabimg">
            <img style="height: 6vw;" src="../assets/img/zixun.png">
        </div>
        <div class="tabtext">资讯</div>
    </router-link>
    <!-- <a href="https://tb.53kf.com/code/client/2214fc723ccc1bc345ceaecd74b06d747/1" class="tab">
        <div class="tabimg">
            <img style="height: 6.2vw;" src="../assets/img/kefu.png">
        </div>
        <div class="tabtext">客服</div>
    </a> -->
    <router-link to="/service" class="tab">
        <div class="tabimg">
            <img style="height: 6.2vw;" src="../assets/img/kefu.png">
        </div>
        <div class="tabtext">客服</div>
    </router-link>
    <router-link v-if="nav=='message'" class="tab" to="/message">
        <div class="tabimg">
            <van-badge :content="store.state.msg" color="#ff9900" :offset="[0,5]" :show-zero="false">
                <img style="height: 6.2vw;" src="../assets/img/xiaoxi2.png">
            </van-badge>            
        </div>
        <div class="tabtext active">消息</div>
    </router-link>
    <router-link v-else class="tab" to="/message">
        <div class="tabimg">
            <van-badge :content="store.state.msg" color="#ff9900" :offset="[0,5]" :show-zero="false">
                <img style="height: 6.2vw;" src="../assets/img/xiaoxi.png">
            </van-badge> 
        </div>
        <div class="tabtext">消息</div>
    </router-link>

    <router-link v-if="nav=='about'" class="tab" to="/about">
        <div class="tabimg">
            <img style="height: 6vw;" src="../assets/img/wode2.png">
        </div>
        <div class="tabtext active">我的</div>
    </router-link>
    <router-link v-else class="tab" to="/about">
        <div class="tabimg">
            <img style="height: 6vw;" src="../assets/img/wode.png">
        </div>
        <div class="tabtext">我的</div>
    </router-link>
  </div>
</template>
<script>
import { useStore } from 'vuex'
export default {
  name: "TabBar",
  props: {
    nav: String
  },
  setup(props){
      let store = useStore();
      return {
          store
      }
  },
  
};
</script>
<style scoped lang="less">
.navTitle {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1001;
    background: linear-gradient(to right, #65b8fc, #8193ff);
    height: 12.5vw;
    text-align: center;
    line-height: 12.5vw;
    color: #fff;
    img {
        height: 4vw;
        position: absolute;
        left: 4vw;
        top: 4.3vw;
    }
}
.tabbar {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1001;
    background-color: #ffffff;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #f8f8f8;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 1vw 0;

    // padding-bottom: constant(safe-area-inset-bottom);
    // padding-bottom: env(safe-area-inset-bottom);
    .tab {
        width: 25%;
        .tabimg {
            height: 6.6vw;
            text-align: center;
            line-height: 6.6vw;
            img {
                width: auto;
            }
        }
        .tabtext {
            text-align: center;
            font-size: 3.4vw;
            color: #2c3e50;
        }
        .active {
            color: #ff9900;
        }
    }
}
</style>